<template>
  <div class="specifications">
    <div
      v-for="specification in getSpecifications"
      :key="specification.getGroupName()"
      class="specifications__group"
    >
      <h3 class="specifications__group-name">
        {{ specification.getGroupName() }}
      </h3>
      <ul class="specifications__list">
        <li
          v-for="specificationItem in specification.items"
          :key="specificationItem.id"
          class="specifications__item"
        >
          <p class="specifications__item-name">
            {{ specificationItem.getName() }}
          </p>
          <span class="specifications__item-line" />
          <MyLink
            v-if="specificationItem.isInteractive"
            name="category"
            :params="{
              categorySlug: getTopParentCategory(product.getMainCategory())
                .slug,
              specifications: specificationItem.getLink(),
            }"
            class="specifications__item-value link"
          >
            {{ specificationItem.getValue() }}
          </MyLink>
          <p v-else class="specifications__item-value">
            {{ specificationItem.getValue() }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import { Specification } from "~/models/specification.model";
import { useCategoriesStore } from "~/store/categoriesStore";

const props = defineProps({
  product: { type: Product, required: true },
  limit: { type: Number, required: false },
});

const { getTopParentCategory } = useCategoriesStore();

const getSpecifications = computed(() => {
  if (undefined === props.limit) {
    return props.product.specifications;
  }

  return props.product.specifications
    .slice(0, props.limit)
    .map(({ group, items }) => {
      return new Specification({
        group,
        items: items.slice(0, props.limit),
      });
    });
});
</script>

<style scoped lang="scss">
.go-to {
  @include font(14, 20, 500);
}
.specifications {
  @include flex-container(column, flex-start);
  gap: 24px;

  &__group {
    @include flex-container(column, flex-start);
    gap: 8px;
  }

  &__list {
    @include flex-container(column, flex-start);
  }

  &__group-name {
    font-weight: 700;

    background-color: var(--color-primary-lightest);

    padding: 8px 16px;
  }

  &__item {
    @include flex-container(row, space-between, flex-end);
    gap: 8px;

    padding: 8px;
  }

  &__item-line {
    height: 0;
    flex-grow: 1;

    border: 1px dashed var(--color-sky-base);

    margin-bottom: 4px;
  }

  &__item-name {
    max-width: max-content;
    width: 100%;

    @include font(14, 20, 500);
    color: var(--color-sky-darker);
  }

  &__item-value {
    max-width: 60%;

    @include font(14, 20);

    text-align: right;
    text-wrap: pretty;
    overflow-wrap: break-word;

    &.link {
      color: var(--color-primary-base);
      font-weight: 500;
      text-decoration: underline;
    }
  }
}
</style>
